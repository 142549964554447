// @flow
import * as React from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'
import { Store } from 'Store.js'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes.js'

import EventLogo from './EventLogo'
import EventLogoFr from './EventLogoFr'

function MainLogo (props) {
    const { state } = React.useContext(Store)
    const isFr = state.locale.language === 'fr'
    return (
        <div className={cn(styles.container, props.className)} style={props.style}>
            {isFr ? <>
                <EventLogoFr primaryColor={props.logoColor || props.theme.mainColor || props.theme.titleColor} secondaryColor={props.titleColor || props.theme.titleColor} className={cn(styles.logo, props.logoClassName || '')}/>
                <span className={styles.title} style={{ color: props.titleColor || props.theme.titleColor }}>{props.title}</span>
            </> : <>
                <span className={styles.title} style={{ color: props.titleColor || props.theme.titleColor }}>{props.title}</span>
                <EventLogo primaryColor={props.logoColor || props.theme.mainColor || props.theme.titleColor} secondaryColor={props.titleColor || props.theme.titleColor} className={cn(styles.logo, props.logoClassName || '')}/>
            </>}
        </div>
    )
}

export default withTheme(themes)(MainLogo)
