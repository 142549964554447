// @flow
import * as React from 'react'
import { AppointmentWidgetWrapper as AppointmentWidget } from '@shift-marketing/event-websites-chatbot'
import '@shift-marketing/event-websites-chatbot/dist/main.css'
import { svgToImage } from '@shift-marketing/event-websites-helper'

import { Store } from 'Store'
import { sendAppointmentDate, sendAutoresponder, sendClientMessage } from 'Actions'
import { Currency } from 'i18n/NumberFormatter'

import withTheme from 'hoc/withTheme'
import useInterval from 'hook/useInterval'
import useTimeout from 'hook/useTimeout'

import Voucher from 'components/Voucher'

import MainLogo from '../MainLogo'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

function HeaderWithVoucher (props) {
    const { theme } = props
    const { state, dispatch } = React.useContext(Store)

    const voucherRef = React.useRef(null)
    const [appointmentWidget, setAppointmentWidget] = React.useState(false)
    const currencyFormatter = React.useMemo(() => Currency(state.currency, state.locale), [state.currency, state.locale])

    useInterval(async () => {
        if (voucherRef.current && state.autoresponderShouldBeSent) {
            await svgToImage(voucherRef.current, async blob => {
                await sendAutoresponder(dispatch, `${state.campaign.incentives.voucher.name}.png`, blob)
            })
        }
    }, state.autoresponderShouldBeSent ? 1000 : null)

    useTimeout(() => {
        if (state.campaign.hasOwnProperty('incentives') && state.campaign.incentives.hasOwnProperty('onlineBooking') && !state.campaign.expired) {
            setAppointmentWidget(
                <AppointmentWidget
                    onlineBooking={state.campaign.incentives.onlineBooking}
                    lead={state.lead}
                    clientName={state.campaign.client.name}
                    expiryDate={state.campaign.expiryDate}
                    actions={{
                        sendAppointmentDate,
                        sendClientMessage
                    }}
                    currencyFormatter={currencyFormatter}
                    colors={theme.appointmentWidget.colors}
                />
            )
        }
    }, 500)

    return (
        <>
            {appointmentWidget}
            <div className={styles.bgWrapper} style={{ backgroundImage: `url(${theme.background})` }}>
                <div className={styles.container}>
                    <div className={styles.logoContainer}>
                        <MainLogo title={state.campaign.client.name}/>
                    </div>

                    <div className={styles.mask} style={{ backgroundImage: `url(${theme.mask})` }}/>
                </div>
            </div>

            <div className={styles.voucher}>
                <Voucher width="100%" ref={voucherRef}/>
            </div>

            <div className={styles.cars}>
                <img src={theme.cars} alt="Cars"/>
            </div>
        </>
    )
}

export default withTheme(supportedThemes)(HeaderWithVoucher)
