
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns71c1947ae23555b74e3c698f545538c1en from './en.yaml'
            i18n.addResourceBundle('en', '71c1947ae23555b74e3c698f545538c1', flattenObject(ns71c1947ae23555b74e3c698f545538c1en), true, true);
        
            import ns71c1947ae23555b74e3c698f545538c1fr from './fr.yaml'
            i18n.addResourceBundle('fr', '71c1947ae23555b74e3c698f545538c1', flattenObject(ns71c1947ae23555b74e3c698f545538c1fr), true, true);
        
        export default '71c1947ae23555b74e3c698f545538c1';
    