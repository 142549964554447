// @flow
import React from 'react'

import { Store } from 'Store'

import styles from 'components/LocaleSwitcher/styles.module.scss'

export default function LocaleSwitcher () {
    const { state, dispatch } = React.useContext(Store)

    const handleClick = React.useCallback(e => {
        e.preventDefault()
        dispatch({
            type: 'SET_LOCALE',
            payload: new Intl.Locale(e.target.dataset['locale']),
        })
    }, [dispatch])

    if (state.campaign.locales.supported.length < 2) {
        return null
    }

    return (
        <div className={styles.switcher}>
            {state.campaign.locales.supported.map(l => (
                <button key={l} className={styles.button} data-locale={l} onClick={handleClick}>{l}</button>
            ))}
        </div>
    )
}
